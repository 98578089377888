import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Event } from '../store/calendar/interfaces/event.interface';
import { Observable } from 'rxjs';
import { EventSearch } from '../store/calendar/interfaces/event-search.interface';

@Injectable({
  providedIn: 'root',
})
export class AdmissionsCalendarService {
  private readonly baseApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {
    this.baseApiUrl = `${this.apiUrl}/calendar`;
  }

  getCalendarAuthLink(): Observable<any> {
    return this.http.get<any>(`${this.baseApiUrl}/auth-link`);
  }

  getToken(code: string): Observable<void> {
    return this.http.post<void>(`${this.baseApiUrl}/oauth2callback`, code);
  }

  getEvents(params: EventSearch, patientId: string): Observable<{ pageToken: string; items: Event[] }> {
    return this.http.get<{ pageToken: string; items: Event[] }>(`${this.baseApiUrl}/admission/${patientId}`, {
      params: <any>params,
    });
  }

  creatEvent(params: Event, patientId: string, role: string): Observable<Event> {
    return this.http.post<Event>(`${this.baseApiUrl}/${role}/${patientId}`, params);
  }

  getEventById(id: string, uid: string): Observable<Event> {
    return this.http.get<Event>(`${this.baseApiUrl}/admission/${uid}/${id}`);
  }

  updateEvent(id: string, params: Event, uid: string, role: string): Observable<Event> {
    return this.http.put<Event>(`${this.baseApiUrl}/${role}/${uid}/${id}`, { ...params });
  }

  deleteEvent(id: string, uid: string, role: string): Observable<void> {
    return this.http.delete<void>(`${this.baseApiUrl}/${role}/${uid}/${id}`);
  }
}
