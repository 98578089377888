import { EventSearch } from '../calendar/interfaces/event-search.interface';
import { Event } from '../calendar/interfaces/event.interface';

export enum TypeModalEvent {
  Create,
  Edit,
  Look,
  Close,
}

export class GetAdmissionAuthLink {
  static readonly type = '[AdmissionCalendar] Get auth link';
}

export class GetAdmissionToken {
  static readonly type = '[AdmissionCalendar] Get token';

  constructor(public code: string) {}
}

export class GetAdmissionTokenSuccess {
  static readonly type = '[AdmissionCalendar] Get token success';
}

export class GetEvents {
  static readonly type = '[AdmissionCalendar] Get events';

  constructor(
    public params: EventSearch,
    public patientId: string,
  ) {}
}

export class CreateEvent {
  static readonly type = '[AdmissionCalendar] Create event';

  constructor(
    public event: Event,
    public patientId: string,
    public role: string,
  ) {}
}

export class SetSelectedEvent {
  static readonly type = '[AdmissionCalendar] Set selected event';

  constructor(public event: Event) {}
}

export class GetEventById {
  static readonly type = '[AdmissionCalendar] Get event by id';

  constructor(
    public id: string,
    public uid: string,
    public role: string,
  ) {}
}

export class EditEvent {
  static readonly type = '[AdmissionCalendar] Edit event';

  constructor(
    public id: string,
    public event: Event,
    public uid: string,
    public role: string,
  ) {}
}

export class DeleteEvent {
  static readonly type = '[AdmissionCalendar] Delete event';

  constructor(
    public id: string,
    public uid: string,
    public role: string,
  ) {}
}

export class ModalEvent {
  static readonly type = '[AdmissionCalendar] Modal event';

  constructor(
    public stateModal: TypeModalEvent,
    public id: string = null,
  ) {}
}

export class SetEventSuccess {
  static readonly type = '[AdmissionCalendar] Set event success';
}

export class ClearEvents {
  static readonly type = '[AdmissionCalendar] Clear events';
}

export class SetDateStart {
  static readonly type = '[AdmissionCalendar] Set Date Start';

  constructor(public dateStart: string) {}
}
