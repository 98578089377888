<div [class.attention]="yellowHeader" class="message-modal">
  <div class="message-modal__head"></div>
  <div class="message-modal__body">
    <div class="message-modal__title">{{ yellowHeader ? 'Attention' : 'Success' }}</div>
    <div class="message-modal__text">{{ message }}</div>
  </div>
  <div class="message-modal__buttons">
    <ng-container *ngIf="isEditPrevDcw; else defaultTpl">
      <button class="btn violet">Edit</button>
    </ng-container>
    <ng-template #defaultTpl>
      <button
        (click)="loginTOCalendar()"
        *ngIf="isBtnLoginForAdmission"
        [class.attention]="yellowHeader"
        [disabled]="isLogin || disabledButtons.loginToCalendar"
        class="btn">
        Login to calendar
      </button>
      <button
        (click)="close()"
        *ngIf="isBtnClose && !isBtnLoginForAdmission"
        [class.attention]="yellowHeader"
        [class.red]="isBtnLoginForAdmission"
        [disabled]="disabledButtons.close"
        [ngClass]="closeBtnClass"
        class="btn"
        data-e2e="message-popup-close-btn">
        {{ isBtnLoginForAdmission ? 'Cancel' : closeBtnText }}
      </button>
    </ng-template>
  </div>
</div>
